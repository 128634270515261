<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />

    <section
      class="order-page-main-sec order-invoice-page-main-sec agency-design-grid-right"
    >
      <div
         class="desktop-respovsive-main-sec" 
         >
        <div class="editor-dashboard-sec-2" id="printSection">
          <div class="order-list-tabs">
            <div class="invoice-logo">
              <a href="#"><img src="@/assets/common/images/lbhq-logo.svg" /></a>
            </div>
            <div class="invoice-detal-list">
              <ul>
                <li><b>Bill To</b></li>
                <li>
                  <b>{{ name }}</b>
                </li>
                <li v-if="address">
                  {{ address }}<br />{{ state }} {{ postal_code }}, <br />
                  {{ country }}
                </li>
                <li>{{ phone }}</li>
              </ul>
              <ul>
                <li><b>Invoice</b></li>
                <li>Order Date: {{ date }}</li>
                <li>Order Title: {{ order_title }}</li>
                <li>Payment Method: {{ payment_method }}</li>
              </ul>
            </div>
          </div>
          <div class="table-responsive" :class="{ 'missed-opportunity-class': orderType == 'missed_opportunity' }">
            <form>
              <table class="addon-table">
                <tr>
                  <th v-if="orderType == 'missed_opportunity'">Placement URL</th>
                  <th>DA/DR</th>
                  <!-- <th v-if="orderType == 'missed_opportunity'">DR</th> -->
                  <th>Category</th>
                  <th>Destination URL</th>
                  <th v-if="orderType == 'normal'">Minimum Traffic</th>
                  <th v-if="orderType == 'missed_opportunity'">Traffic</th>
                  <th>Content Approval</th>
                  <th>Price</th>
                </tr>
                <tr
                  v-for="billingInvoice in billing_invoice"
                  :key="billingInvoice.id"
                >
                  <td v-if="orderType == 'missed_opportunity'">
                    <a @click="redirectURL(billingInvoice.placement_url)" v-b-tooltip.hover.top="billingInvoice.placement_url" class="destination-link"
                      >{{ billingInvoice.placement_url }}
                      <i class="fas fa-external-link-alt"></i
                    ></a>
                  </td>
                  <!-- <td v-if="orderType == 'missed_opportunity'">{{ billingInvoice.exact_da }}</td>
                  <td v-else>{{ billingInvoice.product }}</td>
                  <td v-if="orderType == 'missed_opportunity'">{{ billingInvoice.exact_dr }}</td> -->
                  <td>{{ billingInvoice.product }}</td>
                  <td>{{ billingInvoice.category }}</td>
                  <td>
                    <a @click="redirectURL(billingInvoice.link)" v-b-tooltip.hover.top="billingInvoice.link" class="destination-link"
                      >{{ billingInvoice.link }}
                      <i class="fas fa-external-link-alt"></i
                    ></a>
                  </td>
                  <td v-if="orderType == 'normal'">{{ billingInvoice.minimum_traffic }}</td>
                  <td v-if="orderType == 'missed_opportunity'">{{ Math.floor(billingInvoice.traffic).toLocaleString() }}</td>
                  <td>{{ billingInvoice.content_approval }}</td>
                  <td v-if="orderType == 'missed_opportunity'">
                    {{ billingInvoice.content_approval != 'None' ? numberFormat((billingInvoice.price + 25),'currency') : numberFormat(billingInvoice.price,'currency') }}
                  </td>
                  <td v-else>
                    {{ numberFormat(billingInvoice.price,'currency') }}
                  </td>
                </tr>
              </table>
            </form>
          </div>

          <!-- Missed Opportunity Proceed Box -->
          <div class="proceed-box" v-if="orderType == 'missed_opportunity'">
            <div class="total-price-table-box proceed-pay-box">
              <table style="width: 100%">
                <tr class="bg-text">
                  <td colspan="2"></td>
                  <td>Qty</td>
                  <td>Price</td>
                </tr>
                <tr v-for="(summaryData) in summaryPaymentData"
                  :key="summaryData.placement">
                  <td colspan="2"><span class="link-text">{{ summaryData.placement }}</span></td>
                  <td>{{ summaryData.total_links }}</td>
                  <td>
                    {{ numberFormat(summaryData.total_cost, "currency") }}
                  </td>
                </tr>
                <!-- <tr>
                  <td colspan="2">Addons:</td>
                  <td>{{ total_addons }}</td>
                  <td>
                    {{ numberFormat(total_addon_cost,'currency') }}
                  </td>
                </tr> -->
                <tr>
                  <td colspan="2">Subtotal:</td>
                  <td colspan="2">
                    USD
                    {{ numberFormat(sub_total,'currency') }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">Discount:</td>
                  <td colspan="2">
                    (USD
                    {{ numberFormat(sub_total - order_total,'currency') }})
                  </td>
                </tr>
                <tr>
                  <td colspan="2">Total:</td>
                  <td colspan="2">
                    <b
                      >USD
                      {{ numberFormat(order_total,'currency') }}</b
                    >
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!-- Normal Order Proceed Box -->
          <div v-else class="proceed-box">
            <div class="total-price-table-box proceed-pay-box">
              <table style="width: 100%">
                <tr class="bg-text">
                  <td colspan="2"></td>
                  <td>Qty</td>
                  <td>Price</td>
                </tr>
                <tr v-if="da_20_count > 0">
                  <td colspan="2">{{ DADRText }} 20 or Below</td>
                  <td>{{ da_20_count }}</td>
                  <td>
                    {{ numberFormat(da_20_total,'currency') }}
                  </td>
                </tr>
                <tr v-if="da_30_count > 0">
                  <td colspan="2">{{ DADRText }} 30+</td>
                  <td>{{ da_30_count }}</td>
                  <td>
                    {{ numberFormat(da_30_total,'currency') }}
                  </td>
                </tr>
                <tr v-if="da_40_count > 0">
                  <td colspan="2">{{ DADRText }} 40+</td>
                  <td>{{ da_40_count }}</td>
                  <td>
                    {{ numberFormat(da_40_total,'currency') }}
                  </td>
                </tr>
                <tr v-if="da_50_count > 0">
                  <td colspan="2">{{ DADRText }} 50+</td>
                  <td>{{ da_50_count }}</td>
                  <td>
                    {{ numberFormat(da_50_total,'currency') }}
                  </td>
                </tr>
                <tr v-if="da_60_count > 0">
                  <td colspan="2">{{ DADRText }} 60+</td>
                  <td>{{ da_60_count }}</td>
                  <td>
                    {{ numberFormat(da_60_total,'currency') }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">Total Links:</td>
                  <td>{{ billing_invoice.length }}</td>
                  <td>
                    {{ numberFormat(links_total,'currency') }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">Addons:</td>
                  <td>{{ total_addons }}</td>
                  <td>
                    {{ numberFormat(total_addon_cost,'currency') }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">Subtotal:</td>
                  <td colspan="2">
                    USD
                    {{ numberFormat(sub_total,'currency') }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">Discount:</td>
                  <td colspan="2">
                    (USD
                    {{ numberFormat(sub_total - order_total,'currency') }})
                  </td>
                </tr>
                <tr>
                  <td colspan="2">Total:</td>
                  <td colspan="2">
                    <b
                      >USD
                      {{ numberFormat(order_total,'currency') }}</b
                    >
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="btn-box-list">
            <a href="#" @click="downloadBillingInvoice('invoice')"
              ><i class="fas fa-download"></i> Download</a
            >
            <a href="#" @click="sendInvoice()"
              ><i class="fas fa-envelope"></i> Email</a
            >
            <a href="#" @click="printSection('invoice')" id="print-button"
              ><i class="fas fa-print"></i> Print</a
            >
          </div>
        </div>

        <div class="bottom-bar">
          <ul>
            <li>219 N Brown Ave,Orlando, FL 32801</li>
            <li>© {{new Date().getFullYear()}} - linkbuildinghq.com</li>
            <li>
              <a href="mailto:letstalk@linkbuildinghq.com"
                >letstalk@linkbuildinghq.com</a
              >
            </li>
          </ul>
        </div>
      </div>
    </section>
    <iframe ref="printFrame" style="display: none"></iframe>
  </div>
</template>

<style></style>

<script>
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { numberFormat } from '@/prod.js';
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
  components: {
    HeaderView,
    LoaderComponent,
    // pdfInvoice,
  },
  mounted() {
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    this.invoice_id = id;
    this.getBillingInvoice();
  },
  data() {
    return {
      numberFormat,
      loading: false,
      options: {
        format: "MM/DD/YYYY",
        useCurrent: false,
      },
      plugins: [{ src: "@/plugins/vue3-html2pdf", mode: "client" }],
      pageBreak: false,
      invoice_id: "",
      download: "",
      billing_invoice: [],
      address: "",
      state: "",
      postal_code: "",
      city_name: "",
      country: "",
      date: "",
      name: "",
      payment_method: "",
      phone: "",
      order_title: "",
      order_total: "",
      discount: "",
      sub_total: "",
      links_total: "",
      total_addons: "",
      total_addon_cost: "",
      da_20_count: 0,
      da_20_total: 0,
      da_30_count: 0,
      da_30_total: 0,
      da_40_count: 0,
      da_40_total: 0,
      da_50_count: 0,
      da_50_total: 0,
      da_60_count: 0,
      da_60_total: 0,
      filename: "billing",
      DADRText: 'DA',
      orderType: 'normal',
    };
  },
  methods: {
    async getBillingInvoice(downloadVal) {
      this.loading = true;
      this.download = downloadVal == "invoice" ? downloadVal : "";
      try {
        let res = await this.$store.dispatch("Billing/getInvoiceDataAction", {
          id: this.invoice_id,
          download: this.download,
        });
        if(res.invoice_detail.length > 0){
          this.orderType = res.invoice_detail[0].order_type;
        }
        this.billing_invoice = res.invoice_detail;
        this.address = res.agency_address;
        this.country = res.country;
        this.state = res.state;
        this.postal_code = res.postal_code;
        this.city_name = res.city_name;
        this.date = res.date;
        this.name = res.name;
        this.payment_method = res.payment_method;
        this.phone = res.phone;
        this.order_title = res.order_title;
        this.discount = res.discount;
        this.sub_total = res.sub_total;
        this.order_total = res.order_total;
        this.links_total = res.links_total;
        this.total_addons = res.total_addons;
        this.total_addon_cost = res.total_addon_cost;
        this.da_20_count = res.da_20_count;
        this.da_20_total = res.da_20_total;
        this.da_30_count = res.da_30_count;
        this.da_30_total = res.da_30_total;
        this.da_40_count = res.da_40_count;
        this.da_40_total = res.da_40_total;
        this.da_50_count = res.da_50_count;
        this.da_50_total = res.da_50_total;
        this.da_60_count = res.da_60_count;
        this.da_60_total = res.da_60_total;
        this.filename = res.filename;
        if(res.invoice_detail[0].product.startsWith("DR")){
          this.DADRText = 'DR';
        }
        if(this.orderType == "missed_opportunity"){
          this.paymentSummaryMO();
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$router.push("/dashboard");
      }
    },
    paymentSummaryMO(){
      this.summaryPaymentData = [];
      const result = {};

      this.billing_invoice.forEach(placement => {
          const url = placement.placement_url;
          const cost = placement.total_price;
       
          if (!result[url]) {
              result[url] = { placement: url, total_links: 0, total_cost: 0 };
          }

          result[url].total_links += 1;
          result[url].total_cost += cost;
          if(placement.content_approval != "None"){
            result[url].total_cost += 25;
          }
      });
      this.summaryPaymentData = result;
    },

    async sendInvoice() {
      this.loading = true;
      try {
        let res = await this.$store.dispatch(
          "Billing/sendInvoiceEmailDataAction",
          this.invoice_id
        );
        if (res.success) {
          this.loading = false;
          this.messages = res.message;
          toast.success(res.message, { timeout: 3000 });
        }
      } catch (error) {
        this.loading = false;
        this.errors = error.response.data.errors;
      }
    },

    async printSection(printVal) {
      this.loading = true;
      try {
        const response = await this.$store.dispatch(
          "Billing/getInvoiceDataAction",
          {
            id: this.invoice_id,
            download: printVal,
          }
        );
        let contentBlob = new Blob([response], {
          type: "application/pdf",
        });

        // Create a URL for the content
        // const contentUrl = URL.createObjectURL(contentBlob);
        let contentUrl = document.createElement("a");
        contentUrl.href = window.URL.createObjectURL(contentBlob);

        // Display the content in the iframe
        const printFrame = this.$refs.printFrame;
        printFrame.src = contentUrl;

        // Wait for a moment to ensure the content is loaded
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Print the content
        printFrame.contentWindow.print();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        toast.error(error, { timeout: 3000 });
      }
    },

    async downloadBillingInvoice(downloadVal) {
      this.loading = true;
      try {
        const response = await this.$store.dispatch(
          "Billing/getInvoiceDataAction",
          {
            id: this.invoice_id,
            download: downloadVal,
          }
        );
        let blob = new Blob([response], {
          type: "application/pdf",
        });
        const filename = this.filename;
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        toast.error(error, { timeout: 3000 });
      }
    },

    async generateReport() {
      this.$refs.pdfComponentRef.generatePDFReport();
    },
    redirectURL(url) {
      const pattern = /^(http:\/\/|https:\/\/)/i;
      const hasHttpOrHttps = pattern.test(url);

      let finalUrl = url;
      if (!hasHttpOrHttps) {
        finalUrl = "https://" + url;
      }
      console.log(finalUrl);
      const newTab = window.open(finalUrl, "_blank");
      if (newTab) {
        newTab.focus();
      }
    },
  },
  watch: {},
  computed: {},
};
</script>
