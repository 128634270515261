<template>
  <div class="home">
    <LoaderComponent v-show="loading" :loaderText="loaderText" />
    <HeaderView />
    <section class="
          dashboard-page-main-sec
          order-page-main-sec
          track-order-main-sec
          agency-design-grid-right
          draft-order-page-sec
          domain-detail-page-sec
        ">

      <div class="row">
        <div class="col-md-12">
          <div class="order-input-field">
            <div class="reports-heading select">
              <select
                :v-if="domains"
                @change="searchDomain(filter.domain)"
                class="form-control m-input"
                v-model="filter.domain"
              >
                <option
                  v-for="(domain, index) in domains"
                  :key="index"
                  :value="domain.text"
                >
                  {{ domain.text }}
                </option>
              </select>
            </div>
            <div class="date-picker-box">
              <div class="form-row">
                <div class="col-md-12">
                  <div class="download-icon-list">
                    <div @click="downloadFile()" class="top_icons">
                      Download Report <i class="fas fa-arrow-down" v-b-tooltip.hover.top="'Export CSV'"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="text-heading-2">Domain : {{ filter.domain }} </div> -->
          <div class="order-count-list domain-box">
              <ul>
                  <li>
                    <div class="text-1">
                      <div class="icon-box" style="background: #F4F2FF">
            <img src="@/assets/common/images/dashboard-icon-4.png" />
          </div>
                
                    </div>

                    <div class="text-2">
                      <div
                        class="count-text"
                        v-text="stats.total_links"
                      >
                      </div>
                      Total Links
                    </div>
                  </li>
                  <li>
                    <div class="text-1">
                      <div class="icon-box" style="background: #FF8E9E26">
                        <img src="@/assets/common/images/outreaching-icon.png" />
                      </div>
                
                    </div>

                    <div class="text-2">
                      <div
                        class="count-text"
                        v-text="stats.total_in_outreaching"
                      >
                      </div>
                      In Outreaching
                    </div>
                  </li>
                  <li>
                    <div class="text-1">
                      <div class="icon-box" style="background: #FEB01926">
                        <img src="@/assets/common/images/writing-icon.png" />
                      </div>
                
                    </div>

                    <div class="text-2">
                      <div
                        class="count-text"
                        v-text="stats.total_in_writing"
                      >
                      </div>
                      In Writing
                    </div>
                  </li>
                  <li>
                    <div class="text-1">
                      <div class="icon-box" style="background: #8F7AD826">
                        <img src="@/assets/common/images/submission-icon.png" />
                      </div>
                
                    </div>

                    <div class="text-2">
                      <div
                        class="count-text"
                        v-text="stats.total_in_submission"
                      >
                      </div>
                      In Submission
                    </div>
                  </li>
                  <li>
                    <div class="text-1">
                      <div class="icon-box" style="background: #00E39626">
                        <img src="@/assets/common/images/live-links-icon.png" />
                      </div>
                
                    </div>

                    <div class="text-2">
                      <div
                        class="count-text"
                        v-text="stats.total_delivered_links"
                      >
                      </div>
                      Live Links
                    </div>
                  </li>
              </ul>
            </div>
            <div class="text-heading-2">
              Links
          </div>
          <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
            <div class="table-responsive">
              <table class="addon-table">
                <tr>
                  <th>Link ID</th>
                  <th>Destination Url</th>
                  <th>Live Url</th>
                  <th>Anchor Text</th>
                  <th>Published DA</th>
                  <th>Published Date</th>
                  <th>Status</th>
                </tr>
                <tr v-for="domainDetail in domain_detail_data" :key="domainDetail.link_id">
                  <td>{{ domainDetail.link_id }}</td>
                  <td class="destination-text" v-b-tooltip.hover.top="domainDetail.destination_link">
                    <a @click="redirectURL(domainDetail.destination_link)"
                      ><span>{{ domainDetail.destination_link }}</span>
                      <i
                        v-if="domainDetail.destination_link !== true
                            ? domainDetail.destination_link
                            : domainDetail.destination_link
                        "
                        class="fas fa-external-link-alt"
                      ></i
                    ></a>
                  </td>
                  <td class="destination-text" v-b-tooltip.hover.top="domainDetail.live_link"><template v-if="domainDetail.live_link !== true
                        ? domainDetail.live_link
                        : domainDetail.live_link
                    "><a @click="redirectURL(domainDetail.live_link)"><span>{{ domainDetail.live_link }} </span> <i v-if="domainDetail.live_link !== true
                      ? domainDetail.live_link
                      : domainDetail.live_link
                  " class="fas fa-external-link-alt"></i></a></template></td>
                  <td><div v-b-tooltip.hover.top="domainDetail.anchor_text">{{ domainDetail.anchor_text }}</div></td>
                  <td>{{ domainDetail.da }}</td>
                  <td>{{ domainDetail.published_at }}</td>
                  <td class="status-btns">
                      <a
                        class="btn-success" style="backgroundColor: #41b883; color: #fff"
                        v-if="domainDetail.status == 'Delivered'"
                        >{{ domainDetail.status }}</a
                      >
                      <a
                        class="btn-secondary" style="backgroundColor: #f4516c; color: #fff"
                        v-if="domainDetail.status == 'Not Delivered'"
                        >{{ domainDetail.status }}</a
                      >
                      <a
                        class="btn-secondary" style="backgroundColor: #ffb822; color: #fff"
                        v-if="domainDetail.status == 'Outreaching'"
                        >{{ domainDetail.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: #00b6cb; color: #fff"
                        v-if="domainDetail.status == 'In Writing'"
                        >{{ domainDetail.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: #36a3f7; color: #fff"
                        v-if="domainDetail.status == 'Pending Review'"
                        >{{ domainDetail.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: #9816f4; color: #fff"
                        v-if="domainDetail.status == 'Refund'"
                        >{{ domainDetail.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: #36a3f7; color: #fff"
                        v-if="domainDetail.status == 'Link Submission'"
                        >{{ domainDetail.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: red; color: #fff"
                        v-if="domainDetail.status == 'Cancel'"
                        >{{ domainDetail.status }}</a
                      >
                    </td>
                </tr>
              </table>
              <div class="total-records-list">
                <p>Showing {{ rows.length }} of {{ totalRecords }} records</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-list">
        <paginate
          :click-handler="selectedDomain"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="2"
          :container-class="'className'"
          :records="length"
          v-model="page"
          :per-page="per_page"
        >
        </paginate>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import Paginate from "vuejs-paginate-next";
export default {
  components: {
    HeaderView,
    LoaderComponent,
    Paginate
  },
  mounted() {
    this.selectedDomain();
  },
  data() {
    return {
      loading: false,
      date: new Date(),
      dateFormat: 'dd-MM-yyyy',
      options: {
        format: "MM/DD/YYYY",
        useCurrent: false,
      },
      rows: [],
      totalRecords: 0,
      per_page: 50,
      page: 1,
      last_page: 1,
      length: "",
      filter: {
        columnTo: "order_id",
        orderBy: "desc",
        domain: "",
        download: 'no'
      },
      domain_detail_data: [],
      stats: {
        total_links: 0,
        total_in_outreaching: 0,
        total_in_writing: 0,
        total_in_submission: 0,
        total_delivered_links: 0,
        total_pending_links: 0,
      },
      domains: [],
      loaderText: ''
    };
  },
  methods: {
    async domainList() {
      try {
        let res = await this.$store.dispatch("Dashboard/getDomainListDataAction");
        this.domains = res.clients;
      } catch (e) {
        this.loading = false;
      }
    },

    selectedDomain(){
      var url = window.location.pathname;
      var domain = url.substring(url.lastIndexOf('/') + 1);
      if (domain) {
        this.domainList();
        this.filter.domain = decodeURIComponent(domain);
        this.getDomainDetail(domain);
      }
    },
    searchDomain(domain){
      this.page = 1;
      this.getDomainDetail(domain);
    },
    async getDomainDetail(domain) {
      this.loading = true;
      this.filter.domain = decodeURIComponent(domain);
      this.filter.download = 'no';
      try {
        let res = await this.$store.dispatch(
          "AgencyReport/DomainReportDetailDataAction",
          { 
            filters: this.filter,
            per_page: this.per_page, 
            page: this.page 
          }
        );
        this.domain_detail_data = res.data;
        this.totalRecords = res.meta.total;
        this.rows = res.data;
        this.page = res.meta.current_page;
        this.per_page = res.meta.per_page;
        this.last_page = res.meta.last_page;
        this.length = res.meta.total;
        this.stats.total_links = res.total_links;
        this.stats.total_in_outreaching = res.total_in_outreaching;
        this.stats.total_in_writing = res.total_in_writing;
        this.stats.total_in_submission = res.total_in_submission;
        this.stats.total_delivered_links = res.total_delivered_links;
        this.stats.total_pending_links = res.total_pending_links;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    redirectURL(url) {
      const pattern = /^(http:\/\/|https:\/\/)/i;
      const hasHttpOrHttps = pattern.test(url);

      let finalUrl = url;
      if (!hasHttpOrHttps) {
          finalUrl = 'https://' + url;
      }

      const newTab = window.open(finalUrl, '_blank');
      if (newTab) {
          newTab.focus();
      }
    },

    async downloadFile() {
      this.loaderText = 'Please wait while we are processing your request';
      this.loading = true;
      this.filter.download = 'yes';
      try {
        const response = await this.$store.dispatch(
          "AgencyReport/DomainReportDetailDataAction",
          { 
            filters: this.filter,
            per_page: this.per_page, 
            page: this.page 
          }
        );
        let blob = new Blob([response], {
          type: "application/csv",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Domain-Detail.csv';
        link.click();
        this.loaderText = '';
        this.loading = false;
      } catch (e) {
        this.loaderText = '';
        this.loading = false;
      }
    },
  },
};
</script>
