import apiInstance from "./apiInstance";

export class ReportServices{



    static DomainReportAPI(payload){
        return apiInstance.post('/api/domain-data',payload);
    }

    static DomainReportDetailAPI(payload){
        return apiInstance.get(`/api/domain-links-data/${payload.per_page}?page=${payload.page}`,{ params: payload.filters });
    }
    static OrderReportAPI(payload){
        return apiInstance.post('/api/order-data',payload);
    }

    static OrderReportDetailAPI(payload){
        return apiInstance.get(`/api/links-order-data/${payload.per_page}?page=${payload.page}`,{ params: payload.filters });
    }

    static OrderGraphAPI(payload){
        return apiInstance.post('/api/order-graph',payload);
    }

    static DomainGraphAPI(payload){
        return apiInstance.post('/api/domain-graph',payload);
    }

    static getOrderIdAPI(){
        return apiInstance.get('/api/order-list');
    }
}