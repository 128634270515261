<template>
  <div class="modal-list">
    <b-modal
      id="modal-box-1"
      centered
      title="Order Multiple Links"
      :no-close-on-backdrop="true"
    >
      <p class="order-para">
        Need to order multiple links? Follow the steps below:
      </p>
      <div class="step-box-list">
        <ul>
          <li>
            <div class="step-text">Step 1</div>
            <div class="icon-image">
              <img
                src="@/assets/common/images/Icon-awesome-cloud-download-alt.svg"
              />
            </div>
            <div class="text-1">
              Download the order details template CSV file here
            </div>
          </li>
          <li>
            <div class="step-text">Step 2</div>
            <div class="icon-image">
              <img src="@/assets/common/images/Icon-ionic-md-document.svg" />
            </div>
            <div class="text-1">
              Populate the file with your order details or use a file with
              identical fields
            </div>
          </li>
          <li>
            <div class="step-text">Step 3</div>
            <div class="icon-image">
              <img
                src="@/assets/common/images/Icon-awesome-cloud-upload-alt.svg"
              />
            </div>
            <div class="text-1">Upload the file and finalize your order</div>
          </li>
        </ul>
      </div>
      <div class="btn-box">
        <a
          href="/files/Link Building HQ Multiple Upload.xlsx"
          download
          class="btn-1 btn-2"
        >
          Download Sample CSV File</a
        >
        <a
          href="#"
          class="btn-1"
          @click="handleFileUploadBtnClick"
          data-bs-dismiss="modal"
          >Upload CSV File</a
        >
        <input
          id="excel_upload"
          type="file"
          class="file-hide"
          @change="handleFileChange"
        />
      </div>
    </b-modal>
    <b-modal
      id="modal-box-2"
      centered
      title="Your order is saved in Draft"
      :no-close-on-backdrop="true"
    >
      <p class="order-para">With Order named <b>"March's Link"</b></p>
    </b-modal>
    <b-modal
      id="modal-box-3"
      centered
      title="What is Domain Authority (DA)?"
      :no-close-on-backdrop="true"
    >
      <p class="order-para">
        The Domain authority of a website describes its relevance for a specific
        subject area or industry. Domain Authority is a search engine ranking
        score developed by Moz. This relevance has a direct impact on its
        ranking by search engines, trying to assess domain authority through
        automated analytic algorithms.
      </p>
      <div class="img-box">
        <!-- <img src="assets/common/images/video.png" /> -->
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/O-HEReYinEI"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </b-modal>
    <b-modal
      id="modal-box-4"
      centered
      title="Mark Approved"
      :no-close-on-backdrop="true"
    >
      <form class="approved-select">
        <p class="order-para">Are you sure to approve this order?</p>
        <div class="form-group">
          <b-form-select v-model="approved_at">
            <option value="1" selected>Yes</option>
            <option value="0">No</option>
          </b-form-select>
        </div>
        <div class="btn-box">
          <b-button type="button" @click="approvedDraft" class="btn-1"
            >Submit</b-button
          >
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import XLSX from "xlsx";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  mounted() {},
  data() {
    return {
      loaded: false,
      loading: false,
      rawFile: null,
      workbook: null,
      tableData: {
        header: [],
        body: [],
      },
      approved_at: 1,
    };
  },
  methods: {
    approvedDraft() {
      this.$emit("approvedDraft", this.approved_at);
    },
    handleFileUploadBtnClick() {
      // this.$parent.loaded = true;
      this.clearAllData();
      document.getElementById("excel_upload").click();
    },
    handleFileChange(e) {
      this.$parent.loaded = true;
      this.$parent.loaderText = 'Please wait while we are processing your request';
      if (this.rawFile !== null) {
        this.$parent.loaded = true;
        return;
      }
      this.rawFile = e.target.files[0];
      if (
        this.rawFile.type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        toast.error("invalid file!", { timeout: 3000 });
        this.$parent.loaded = false;
        this.$parent.loaderText = '';
        return;
      }
      this.fileConvertToWorkbook(this.rawFile)
        .then((workbook) => {
          let xlsxArr = XLSX.utils.sheet_to_json(
            workbook.Sheets[workbook.SheetNames[0]]
          );
          this.workbook = workbook;
          // console.log(this.workbook.Strings);
          this.initTable(this.xlsxArrToTableArr(xlsxArr));
          this.$parent.loaderText = '';
          this.$parent.loaded = false;
        })
        .catch((err) => {
          console.error(err);
          this.$parent.loaderText = '';
          this.$parent.loaded = false;
        });
    },

    fileConvertToWorkbook(file) {
      let reader = new FileReader();
      let fixdata = (data) => {
        let o = "",
          l = 0,
          w = 10240;
        for (; l < data.byteLength / w; ++l) {
          o += String.fromCharCode.apply(
            null,
            new Uint8Array(data.slice(l * w, l * w + w))
          );
        }
        o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
        return o;
      };
      return new Promise((resolve, reject) => {
        try {
          reader.onload = (renderEvent) => {
            let data = renderEvent.target.result;
            if (this.rABS) {
              /* if binary string, read with type 'binary' */
              resolve(XLSX.read(data, { type: "binary" }));
            } else {
              /* if array buffer, convert to base64 */
              let arr = fixdata(data);
              resolve(XLSX.read(btoa(arr), { type: "base64" }));
            }
          };
          reader.onerror = (error) => {
            reject(error);
          };
          if (this.rABS) {
            reader.readAsBinaryString(file);
          } else {
            reader.readAsArrayBuffer(file);
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    xlsxArrToTableArr(xlsxArr) {
      let tableArr = [];
      let length = 0;
      let maxLength = 0;
      let maxLengthIndex = 0;
      xlsxArr.forEach((item, index) => {
        length = Object.keys(item).length;
        if (maxLength < length) {
          maxLength = length;
          maxLengthIndex = index;
        }
      });
      let tableHeader = Object.keys(xlsxArr[maxLengthIndex]);
      let rowItem = {};
      xlsxArr.forEach((item) => {
        rowItem = {};
        for (let i = 0; i < maxLength; i++) {
          rowItem[tableHeader[i].toUpperCase()] = item[tableHeader[i]] || "";
        }
        tableArr.push(rowItem);
      });
      return {
        header: tableHeader,
        data: tableArr,
      };
    },
    tableArrToXlsxArr({ data, header }) {
      let xlsxArr = [];
      let tempObj = {};
      data.forEach((rowItem) => {
        tempObj = {};
        rowItem.forEach((item, index) => {
          tempObj[header[index]] = item;
        });
        xlsxArr.push(tempObj);
      });
      return xlsxArr;
    },
    initTable({ data, header }) {
      this.tableData.header = header;
      this.tableData.body = data;
      this.$emit("on-upload", this.tableData);
      //this.fileUploaded(this.tableData)
    },
    clearAllData() {
      document.getElementById("excel_upload").value = null;
      this.tableData = {
        header: [],
        body: [],
      };
      this.$parent.loaderText = '';
      this.rawFile = null;
      this.workbook = null;
    },
  },
};
</script>
