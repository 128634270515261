import { createStore } from 'vuex'
import Auth from './auth';
import Dashboard from './dashboard';
import Product from './product';
import Link from './link';
import Profile from './profile';
import Billing from './billing';
import AgencyUsers from './agencyUsers';
import AgencyReport from './agencyReport';
import createPersistedState from "vuex-persistedstate";
import MissedOpportunity from "./missedOpportunity";

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  modules: {
    Auth,
    Dashboard,
    Product,
    Link,
    Profile,
    Billing,
    AgencyUsers,
    AgencyReport,
    MissedOpportunity
  },
})

