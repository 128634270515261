<template>
  <LoaderComponent v-show="loading" />
  <section class="agency-signUp-main-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <div class="responsive-logo">
            <a href="#"><img src="@/assets/common/images/lbhq-logo.svg" /></a>
          </div>
          <div class="form-box">
            <div class="text-heading">Sign Up</div>
            <div class="account-text-1">
              Enter your details to create your account:
            </div>
            <form id="signup_form">
              <div class="form-group">
                <label>Full Name</label>
                <input type="text" class="form-control" v-model="data.name" />
                <span
                  v-if="errors.name"
                  class="invalid-feedback"
                  style="display: block"
                  role="alert"
                >
                  <strong>{{ errors.name[0] }}</strong>
                </span>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  v-model="data.email"
                />
                <span
                  v-if="errors.email"
                  class="invalid-feedback"
                  role="alert"
                  style="display: block"
                >
                  <strong>{{ errors.email[0] }}</strong>
                </span>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Phone</label>
                <MazPhoneNumberInput
                  v-model="data.phone"
                  show-code-on-list
                  size="md"
                  @update="onUpdate = $event"
                />
                <span
                  v-if="errors.phone"
                  class="invalid-feedback"
                  role="alert"
                  style="display: block"
                >
                  <strong>{{ errors.phone[0] }}</strong>
                </span>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Company</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.company"
                />
                <span
                  v-if="errors.company"
                  class="invalid-feedback"
                  role="alert"
                  style="display: block"
                >
                  <strong>{{ errors.company[0] }}</strong>
                </span>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Password</label>
                <input
                  v-bind:type="[showPassword ? 'text' : 'password']"
                  v-model="data.password"
                  class="form-control"
                />
                <span class="eye-icon" @click="showPassword = !showPassword">
                  <i
                    class="fa"
                    :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"
                  ></i>
                </span>
                <span
                  v-if="errors.password"
                  class="invalid-feedback"
                  role="alert"
                  style="display: block"
                >
                  <strong>{{ errors.password[0] }}</strong>
                </span>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Confirm Password</label>
                <input
                  v-bind:type="[confirmPassword ? 'text' : 'password']"
                  v-model="data.password_confirmation"
                  class="form-control"
                />
                <span
                  class="eye-icon"
                  @click="confirmPassword = !confirmPassword"
                >
                  <i
                    class="fa"
                    :class="[confirmPassword ? 'fa-eye-slash' : 'fa-eye']"
                  ></i>
                </span>
                <span
                  v-if="errors.password_confirmation"
                  class="invalid-feedback"
                  role="alert"
                  style="display: block"
                >
                  <strong>{{ errors.password_confirmation[0] }}</strong>
                </span>
              </div>
              <div class="checkbox">
                <label>
                  <input v-model="data.agree" type="checkbox" /> I agree to the
                  <a
                    target="_blank"
                    href="https://www.linkbuildinghq.com/terms-and-conditions"
                    class="blue-text"
                  >
                    terms and conditions.</a
                  >
                </label>
                <span
                  v-if="errors.agree"
                  class="invalid-feedback"
                  role="alert"
                  style="display: block"
                >
                  <strong>{{ errors.agree[0] }}</strong>
                </span>
              </div>
              <button
                type="button"
                class="btn-1"
                @click="signup"
                :class="{ disabled: !seen }"
              >
                {{ button.text }}
                <b-spinner small v-show="loading"></b-spinner>
              </button>

              <div class="sign-in-text">
                Already have an account?
                <!-- <router-link to="login">Sign in</router-link> -->
                <router-link
                  :to="queryParam ? `login${queryParamValue}` : 'login'"
                  >Sign in</router-link
                >
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-7">
          <ContentBoxComponent></ContentBoxComponent>
        </div>
      </div>
    </div>
  </section>
</template>

<style></style>

<script>
import LoaderComponent from "@/components/LoaderComponent.vue";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import ContentBoxComponent from "@/components/ContentBoxComponent.vue";
import "maz-ui/css/main.css";

export default {
  name: "SignupView",
  components: {
    MazPhoneNumberInput,
    LoaderComponent,
    ContentBoxComponent,
  },
  mounted() {
    this.checkUrlParams();
  },
  data() {
    return {
      loading: false,
      data: {
        name: "",
        email: "",
        company: "",
        phone: null,
        phone_valid: false,
        password: "",
        password_confirmation: "",
        agree: false,
        referred_by: "",
        utm_source: "direct",
        utm_medium: "direct",
        utm_campaign: "direct",
        da: "",
        category: "",
      },
      errors: [],
      button: {
        text: "Sign Up",
      },
      countries: [],
      seen: true,
      confirmPassword: false,
      showPassword: false,
      queryParam: false,
      queryParamValue: "",
    };
  },
  methods: {
    getQueryParameterValue(parameterName) {
      var queryString = location.search.substring(1);
      var queryParams = queryString.split("&");

      for (var i = 0; i < queryParams.length; i++) {
        var param = queryParams[i].split("=");
        if (param[0] === parameterName) {
          return decodeURIComponent(param[1]);
        }
      }
      return null;
    },
    checkUrlParams() {
      var refValue = this.getQueryParameterValue("ref");
      var daValue = this.getQueryParameterValue("da");
      var categoryValue = this.getQueryParameterValue("category");
      var sourceValue = this.getQueryParameterValue("utm_source");
      var mediumValue = this.getQueryParameterValue("utm_medium");
      var campaignValue = this.getQueryParameterValue("utm_campaign");

      if (refValue !== null) {
        this.data.referred_by = refValue;
      }

      if (sourceValue !== null) {
        this.data.utm_source = sourceValue;
      }

      if (mediumValue !== null) {
        this.data.utm_medium = mediumValue;
      }

      if (campaignValue !== null) {
        this.data.utm_campaign = campaignValue;
      }

      if (daValue !== null && categoryValue !== null) {
        this.data.da = daValue;
        this.data.category = categoryValue;
        this.queryParamValue = `?da=${daValue}&category=${categoryValue}`;
        if(sourceValue){
          this.queryParamValue += `&utm_source=${sourceValue}`;
        }
        if(mediumValue){
          this.queryParamValue += `&utm_medium=${mediumValue}`;
        }
        if(campaignValue){
          this.queryParamValue += `&utm_campaign=${campaignValue}`;
        }
        this.queryParam = true;
      }
    },
    async signup() {
      this.loading = true;
      try {
        let res = await this.$store.dispatch("Auth/signUpAction", this.data, {
          root: true,
        });
        if (res.success && res.data.is_email_verified == false) {
          this.loading = false;
          localStorage.setItem("email", this.data.email);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "lbhq-signup",
            data: {
              name: this.data.name,
              email: this.data.email,
              phone: this.data.phone,
              company: this.data.company,
            },
          });
          if (this.queryParam) {
            this.$router.push(`/email-verification${this.queryParamValue}`);
          } else {
            this.$router.push("/email-verification");
          }
        }
      } catch (error) {
        this.loading = false;
        this.errors = error.response.data.errors;
      }
    },
  },
};
</script>
