import { AuthServices } from '@/services/authServices';
// import url from '@/prod.json';
export default {
    namespaced: true,

    state: {
        user: {
            data: {
            },
            token: null,
        }
    },

    getters: {
        getUser: state => state.user.data,
        isVerficationEmailSent: state => state.user.data.isEmailSent,
        authToken: state => state.user.token,
        isAuthenticated: state => state.user.token !== null,
    },

    mutations: {

        // Set User State & Token
        setUser(state, payload) {
            state.user.token = payload.token;
            state.user.data['name'] = payload.name;
            state.user.data['email'] = payload.email;
            state.user.data['balance'] = payload.balance;
            state.user.data['is_email_verified'] = payload.is_email_verified;
            state.user.data['is_wallet'] = payload.is_wallet;
            state.user.data['is_save_card'] = payload.is_save_card;
            state.user.data['is_add_card'] = payload.is_add_card;
            state.user.data['is_all_links'] = payload.is_all_links;
            state.user.data['image'] = payload.image;
            state.user.data['role'] = payload.role;
            state.user.data['referral_link'] = payload.referral_link;
            state.user.data['user_created_at'] = payload.user_created_at;
            state.user.data['trial'] = payload.trial;
            state.user.data['order_count'] = payload.order_count;
            state.user.data['deliveredOrders'] = payload.deliveredOrders;
            state.user.data['last_ordered'] = payload.last_ordered;
            state.user.data['user_token'] = payload.user_token;
        },

        // Update User balance
        userBalanceUpdateMutation(state, payload){
            state.user.data.balance = payload;
        },

        // Update User Profile Image 
        userProfileImageUpdateMutation(state, payload){
            state.user.data.image = payload;
        },

        // Update User Name
        userProfileNameUpdateMutation(state, payload){
            state.user.data.name = payload;
        },

        // // Update User Profile Data
        updateUserDataMutation(state,payload){
            state.user.data.balance = payload.balance;
            state.user.data.is_wallet = payload.is_wallet;
            state.user.data.is_save_card = payload.is_save_card;
            state.user.data.is_add_card = payload.is_add_card;
            state.user.data.is_all_links = payload.is_all_links;
            state.user.data.order_count = payload.order_count;
            state.user.data.deliveredOrders = payload.deliveredOrders;
            state.user.data.last_ordered = payload.last_ordered;
            state.user.data.trial = payload.trial;
        },

        // Logout User
        logoutUser(state) {
            state.user.data = {};
            state.user.token = null;
            setTimeout(() => {
                window.localStorage.clear();
            }, 500);
        },

        // Is Email Verified Mutation
        isEmailVerifiedMutation(state, payload) {
            state.user.data.isEmailVerified = payload;
        },

        // Email Verfication Mail Mutation
        emailVerficationMailMutation(state) {
            state.user.data.isEmailSent = true;
        },

        // Update Email Verificatin Mail Mutation
        updateEmailVerificationMutation(state) {
            state.user.data.isEmailVerified = true;
        },

        // Email Notified Mutation
        emailNotifiedMutation(state, payload) {
            state.user.data.isEmailNotified = payload;
        },

        // Update Recent Project ID
        updateRecentProjectIDMutation(state, payload) {
            state.user.data.pro_id = payload;
        }
    },

    actions: {

        // Login Action
        async loginAction({ commit }, payload) {
            let res = await AuthServices.loginAPI(payload);
            let data = res.data;
            if(data.success){
                commit('setUser', data.data);
            }
            return data;
        },

        async impersonateLoginAction({ commit }, payload) {
            let res = await AuthServices.impersonateLoginAPI(payload);
            let data = res.data;
            if(data.success){
                commit('setUser', data.data);
            }
            return data;
        },

        // SignUp Action
        async signUpAction({ commit }, payload) {
            let res = await AuthServices.signupAPI(payload);
            let data = res.data;
            commit('setUser', data.data);
            return data;
        },

        // Forget Password Action
        async forgetPasswordAction(_, payload){
            let res = await AuthServices.forgetPasswordAPI(payload);
            let data = res.data;  
            return data;
        },

        // Forget Password Action
        async resetPasswordAction({ commit }, payload){
            let res = await AuthServices.resetPasswordAPI(payload);
            let data = res.data; 
            if(data.success){
                commit('setUser', data.data);
            }
            return data;
        },

        // Logout Action
        async logoutAction({ commit }) {
            let res = await AuthServices.logoutAPI();
            let data = res.data;
            commit('logoutUser');
            return data;
        },

        // Is Email Verified Action
        async isEmailVerifiedAction({ commit }, payload){
            let res = await AuthServices.isEmailVerified(payload);
            let data = res.data;
            if(data.success){
                commit('setUser', data.data);
            }
            return data;
        },

        // Resend Email For Verification Action
        async resendEmailForVerificationAction(_, payload){
            let res = await AuthServices.resendEmailVerificationAPI(payload);
            let data = res.data;  
            return data;
        },

        // associate user password Action
        async setPasswordAction({ commit }, payload) {
            let res = await AuthServices.setPasswordAPI(payload);
            let data = res.data;
            if(data.success){
                commit('setUser', data.data);
            }
            return data;
        },

        // agency user password Action
        async setAgencyPasswordAction({ commit }, payload) {
            let res = await AuthServices.setAgencyPasswordAPI(payload);
            let data = res.data;
            if(data.success){
                commit('setUser', data.data);
            }
            return data;
        },

        async getUserDataAction({ commit }, payload) {
            let res = await AuthServices.getUserDataAPI(payload);
            let data = res.data;
            if(data.success){
                commit('updateUserDataMutation', data);
            }
            return data;
        },


    }
}