<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section
      class="dashboard-page-main-sec order-page-main-sec track-order-main-sec agency-design-grid-right draft-order-page-sec domain-detail-page-sec"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="order-input-field">
            <div class="reports-heading">
              <div class="text-heading-2">Domain Report</div>
              <p>Track all your orders and links</p>
            </div>
            <div class="date-picker-box">
              <div class="form-row">
                <div class="col-md-12">
                  <div class="input-daterange input-group" id="m_datepicker">
                    <label>Sort by Date</label>
                    <DatePickerComponent
                      :minDate="minDate"
                      :maxDate="maxDate"
                      :options="options"
                      :position="position"
                      :refVal="'datepickerMenu'"
                      :orderDate="filter.range"
                      @getSearchData="getSearchData"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
            <div class="chartareawrapper">
              <div ref="domainGraph">
                <vue3-chart-js
                  v-if="loading == false"
                  v-bind="{ ...barChart }"
                  :height="400"
                />
              </div>
            </div>
          </div>

          <div class="text-heading-2">Domains</div>

          <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
            <div class="table-responsive">
              <table class="addon-table">
                <tr>
                  <th @click="sortByColumn('domain_name')">
                    Domain<span><i class="fas fa-sort"></i></span>
                  </th>
                  <th @click="sortByColumn('total_links')">
                    Total Links<span><i class="fas fa-sort"></i></span>
                  </th>
                  <th @click="sortByColumn('avg_delivered_links')">
                    Links Delivered % <span><i class="fas fa-sort"></i></span>
                  </th>
                  <th @click="sortByColumn('avg_pending_links')">
                    Links Pending % <span><i class="fas fa-sort"></i></span>
                  </th>
                  <th>Action</th>
                </tr>
                <tr v-for="domainData in domain_data" :key="domainData.domain">
                  <td class="destination-text">
                    <a @click="redirectURL(domainData.domain)"
                      ><span>{{ domainData.domain }}</span>
                    </a>
                  </td>
                  <td>{{ domainData.total_links }}</td>
                  <td>{{ domainData.avg_delivered_links }}%</td>
                  <td>{{ domainData.avg_pending_links }}%</td>
                  <td>
                    <div class="btn-box">
                      <router-link
                        :to="`/domain-detail/${domainData.domain}`"
                        v-b-tooltip.hover.top="'View'"
                        ><i class="fas fa-eye"></i
                      ></router-link>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
// import VueApexCharts from "vue3-apexcharts";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import DatePickerComponent from "@/components/DatePickerComponent.vue";
export default {
  components: {
    HeaderView,
    LoaderComponent,
    DatePickerComponent,
    Vue3ChartJs,
  },
  mounted() {
    this.domainChartReports();
    this.getDomainReport();
  },
  data() {
    return {
      loading: false,
      barChart: {
        type: "bar",
        data: {
          labels: [],
          datasets: [
            {
              label: "Amount",
              data: [],
              backgroundColor: ["#00b6cb"],
              fill: false,
              hoverBackgroundColor: ["#00b6cb"],
            },
          ],
        },
        options: {
          responsive: true,
          barThickness: 10,
          maintainAspectRatio: false,
          scales: {
            y: {
              position: "left",
              beginAtZero: true,
              ticks: {
                callback: function (value) {
                  if (value === 0) {
                    return "$" + value;
                  } else if (value < 1000) {
                    return "$" + value;
                  } else {
                    return "$" + value / 1000 + "K";
                  }
                },
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  var label = context.dataset.label || "";
                  label += ": $" + context.parsed.y;
                  return label;
                },
              },
            },
            legend: {
              position: "bottom",
              display: false,
            },
          },
        },
      },
      date: new Date(),
      dateFormat: "MM-dd-yyyy",
      options: {
        format: "MM/DD/YYYY",
        useCurrent: false,
      },
      domain_date: [
        new Date(new Date().setMonth(new Date().getMonth() - 1)),
        new Date(),
      ],
      minDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      maxDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      filter: {
        columnTo: "domain_name",
        orderBy: "asc",
        range: [
          new Date(new Date().setMonth(new Date().getMonth() - 1)),
          new Date(),
        ],
      },
      domain_data: [],
      domainGraphData: [],
      selectedRange: "Custom Range",
      position: "right",
    };
  },
  methods: {
    getSearchData(value) {
      this.filter.range = value;
      this.domainChartReports();
      this.getDomainReport();
    },
    async getDomainReport() {
      this.loading = true;
      if (this.filter.range === null) {
        this.filter.range = [
          new Date(new Date().setMonth(new Date().getMonth() - 1)),
          new Date(),
        ];
      }
      try {
        let res = await this.$store.dispatch(
          "AgencyReport/DomainReportDataAction",
          this.filter
        );
        this.domain_data = res;
        // this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    async domainChartReports() {
      this.loading = true;
      this.barChart.data.datasets[0].data = [];
      this.barChart.data.labels = [];
      if (this.filter.range === null) {
        this.filter.range = [
          new Date(new Date().setMonth(new Date().getMonth() - 1)),
          new Date(),
        ];
      }
      try {
        let res = await this.$store.dispatch(
          "AgencyReport/DomainGraphDataAction",
          this.filter
        );
        this.domainGraphData = res.data;
        this.loading = false;
        // this.barChart.options.scales.y.suggestedMax = res.max_val.data;
      } catch (e) {
        this.loading = false;
      }
    },

    async updateGraph() {
      this.loading = true;
      this.domainGraphData.forEach((d) => {
        this.barChart.data.datasets[0].data.push(d.data);
        this.barChart.data.labels.push(d.label);
      });
      const domainGraph = this.$refs.domainGraph;
      if (this.domainGraphData.length > 13) {
        domainGraph.style.width = "1900px";
      } else {
        domainGraph.style.width = "100%";
      }
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },

    downloadFile() {
      var jsonObject = this.domain_data;
      var array =
        typeof jsonObject != "object" ? JSON.parse(jsonObject) : jsonObject;

      var Head = [
        [
          '"Domain Name"',
          '"Number Of Links Ordered Life Time"',
          '"Number Of Links Delivered %"',
          '"Number Of Links Pending %"',
        ],
      ];

      var selectedColumns = array.map(function (item) {
        return [
          '"' + item.domain + '"',
          '"' + item.total_links + '"',
          '"' + item.avg_delivered_links + '"',
          '"' + item.avg_pending_links + '"',
        ];
      });

      var csvRows = Head.concat(selectedColumns)
        .map(function (row) {
          return row.join(",");
        })
        .join("\n");

      var csvFile = new Blob([csvRows], {
        type: "text/csv",
      });

      var downloadLink = document.createElement("a");
      downloadLink.download = "Domain.csv";
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    sortByColumn(column) {
      this.filter.orderBy = this.filter.orderBy == "asc" ? "desc" : "asc";
      this.getSortedResults(column, this.filter.orderBy);
    },
    getSortedResults(column, order) {
      this.filter.columnTo = column;
      this.filter.orderBy = order;
      this.getDomainReport();
    },
  },
  watch: {
    domainGraphData: function () {
      this.barChart.data.datasets[0].data = [];
      this.barChart.data.labels = [];
      this.updateGraph();
    },
  },
};
</script>
