<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section
      class="dashboard-page-main-sec order-page-main-sec track-order-main-sec agency-design-grid-right"
    >
      <div class="dashboard-text-box">
        <h2 class="text-heading">
          <span> Welcome Back,</span> {{ userData.name }}
        </h2>
        <div class="btn-box select-domain-box">
          <div class="select">
            <select
              :v-if="all_clients"
              @change="searchDomain()"
              class="form-control m-input"
              v-model="search.client"
            >
              <option value="all">Select Domain</option>
              <option
                v-for="(client, index) in all_clients"
                :key="index"
                :value="client.text"
              >
                {{ client.text }}
              </option>
            </select>
          </div>
          <router-link to="/product" class="btn-1">Place an Order</router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <UserStatesView :userStatsData="userStatsData" />
        </div>
        <GraphStatusView
          :clientStatusChartData="clientStatusChartData"
          :DAStatusChartData="DAStatusChartData"
        />
      </div>

      <div class="row" v-if="content_Approvel.length > 0">
        <div class="col-md-12">
          <div class="text_heading_top_bar">
            <div class="text-heading-2">Content Approval</div>
            <div class="btn-box">
              <router-link to="/order/pending-review" class="btn-1"
                >View All</router-link
              >
            </div>
          </div>
          <ContentApprovalView :content_Approvel="content_Approvel" />
        </div>
      </div>

      <div class="row" v-if="missed_opportunities.length > 0">
        <div class="col-md-12">
          <div class="text_heading_top_bar">
            <div class="text-heading-2">Premium Placements</div>
            <div class="btn-box">
              <router-link to="/missed-opportunity" class="btn-1"
                >View All</router-link
              >
            </div>
          </div>
          <MissedOpportunitiesView
            :missed_opportunities="missed_opportunities"
          />
        </div>
      </div>

      <div class="row" v-if="draft_orders.length > 0">
        <div class="col-md-12">
          <div class="text_heading_top_bar">
            <div class="text-heading-2">Draft Orders</div>
            <div class="btn-box">
              <router-link to="/draft-order" class="btn-1"
                >View All</router-link
              >
            </div>
          </div>
          <DraftOrderView
            :draft_orders="draft_orders"
            :dashboardDraft="dashboardDraft"
          />
        </div>
      </div>

      <div class="row" v-if="live_links.length > 0">
        <div class="col-md-12">
          <div class="text_heading_top_bar">
            <div class="text-heading-2">Live Links</div>
            <div class="btn-box">
              <router-link to="/order/delivered" class="btn-1"
                >View All</router-link
              >
            </div>
          </div>
          <LiveLinksView :live_links="live_links" />
        </div>
      </div>
    </section>
  </div>
</template>
 
<script>
import HeaderView from "@/components/layout/HeaderComponent.vue";
import UserStatesView from "@/components/dashboardComponents/UserStates.vue";
import DraftOrderView from "@/components/dashboardComponents/draftOrder.vue";
import LiveLinksView from "@/components/dashboardComponents/liveLinks.vue";
import MissedOpportunitiesView from "@/components/dashboardComponents/missedOpportunities.vue";
import GraphStatusView from "@/components/dashboardComponents/graphStatus.vue";
import ContentApprovalView from "@/components/dashboardComponents/contentApproval.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";

export default {
  name: "DashboardView",
  components: {
    HeaderView,
    DraftOrderView,
    GraphStatusView,
    UserStatesView,
    ContentApprovalView,
    LiveLinksView,
    MissedOpportunitiesView,
    LoaderComponent,
  },
  mounted() {
    setTimeout(() => (this.loading = false), 3000);
    this.domainList();
    this.searchDomain();
    this.getDraftOrder();
    this.getContentApproval();
    this.getLiveLinks();
    this.getOpportunites();
  },

  data() {
    return {
      dashboardDraft: true,
      loading: true,
      filter: {
        s: "",
        e: "",
        order_date: "",
        columnTo: "id",
        orderBy: "desc",
        per_page: 5,
        page: 1,
        last_page: 1,
        length: "",
        date_form: "",
        date_to: "",
      },
      opportunityFilter: {
        placement_url: "",
        category: null,
        status: 'New',
      },
      clientstatus: {
        loaded: false,
        data: null,
        client: "all",
        show: false,
      },

      linksByDA: {
        loaded: false,
        data: null,
        client: "all",
        show: false,
      },

      linksByStatus: {
        loaded: false,
        data: null,
        client: "all",
        show: false,
      },
      tabs: [
        { chartdata: true },
        { clientstatus: false },
        { linksByDA: false },
      ],
      columns: [
        { label: "Destination Link", field: "destination_link" },
        { label: "Anchor Text", field: "anchor_text" },
        { label: "Domain Authority", field: "da" },
        { label: "Date Published", field: "published_at", sortable: false },
      ],
      pcolumns: [
        { label: "Destination Link", field: "destination_link" },
        { label: "Anchor Text", field: "anchor_text" },
        { label: "Placement Opportunity", field: "placement_opportunity" },
        {
          label: "Available Since",
          field: "pending_review_date",
          sortable: false,
        },
        { label: "Action", field: "action" },
      ],
      rows: [],
      prows: [],
      page: 1,
      per_page: 5,
      expanded: null,
      userStatsData: [],
      draft_orders: [],
      live_links: [],
      content_Approvel: [],
      missed_opportunities: [],
      domainChartData: [],
      DAStatusChartData: [],
      // DRStatusChartData: [],
      clientStatusChartData: [],
      all_clients: [],
      search: {
        client: "all",
      },
      loarder1: false,
      loarder2: false,
      loarder3: false,
      loarder4: false,
      loarder5: false,
      loarder6: false,
      loarder7: false,
      loarder8: false,
    };
  },
  methods: {
    async domainList() {
      try {
        let res = await this.$store.dispatch(
          "Dashboard/getDomainListDataAction"
        );
        this.all_clients = res.clients;
      } catch (e) {
        this.loading = false;
      }
    },

    searchDomain() {
      let searchValue = this.search.client;
      this.userStats(searchValue);
      this.chartReports(searchValue);
      this.DAChartReports(searchValue);
      // this.DRChartReports(searchValue);
    },

    async userStats(searchValue) {
      this.loading = true;
      this.loarder1 = true;
      this.loarder2 = true;
      this.loarder3 = true;
      this.loarder4 = true;
      this.loarder5 = true;
      this.loarder6 = true;
      this.loarder7 = true;
      this.loarder8 = true;
      try {
        let res = await this.$store.dispatch(
          "Dashboard/getAllDashboardDataAction",
          {
            client: searchValue,
          }
        );
        this.userStatsData = res;
        // this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    async chartReports(searchValue) {
      this.loading = true;
      try {
        let res = await this.$store.dispatch(
          "Dashboard/getDashboardReportsDataAction",
          {
            client: searchValue,
          }
        );
        this.clientStatusChartData = res.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    // async domainChartReports(searchValue) {
    //   this.loading = true;
    //   try {
    //     let res = await this.$store.dispatch(
    //       "Dashboard/getDomainReportsDataAction",{
    //         client: searchValue,
    //       });
    //      this.domainChartData = res;
    //      this.all_clients = res.clients;
    //      this.loading = false;
    //   } catch (e) {
    //    this.loading = false;
    //   }
    // },

    async DAChartReports(searchValue) {
      // this.loading = true;
      try {
        let res = await this.$store.dispatch(
          "Dashboard/getDAReportsDataAction",
          {
            client: searchValue,
          }
        );
        this.DAStatusChartData = res;
        // this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    // async DRChartReports(searchValue) {
    //   // this.loading = true;
    //   try {
    //     let res = await this.$store.dispatch(
    //       "Dashboard/getDRReportsDataAction",
    //       {
    //         client: searchValue,
    //       }
    //     );
    //     this.DRStatusChartData = res;
    //     // this.loading = false;
    //   } catch (e) {
    //     this.loading = false;
    //   }
    // },

    async getContentApproval() {
      try {
        let res = await this.$store.dispatch(
          "Dashboard/getContentApprovelrDataAction"
        );
        this.content_Approvel = res;
      } catch (e) {
        console.log(e);
      }
    },

    async getOpportunites() {
      this.filter.s = this.filter.order_date ? this.filter.order_date[0] : "";
      this.filter.e = this.filter.order_date ? this.filter.order_date[1] : "";
      try {
        let res = await this.$store.dispatch(
          "MissedOpportunity/getMissedOpportunityDataAction",
          this.opportunityFilter
        );
        this.missed_opportunities = res.data;
      } catch (e) {
        console.log(e);
      }
    },

    async getDraftOrder() {
      // this.loading = true;
      this.filter.s = this.filter.order_date ? this.filter.order_date[0] : "";
      this.filter.e = this.filter.order_date ? this.filter.order_date[1] : "";
      try {
        let res = await this.$store.dispatch(
          "Product/getDraftOrderAction",
          this.filter
        );
        this.draft_orders = res.data;
        this.paginationData = res;
        this.filter.page = res.meta.current_page;
        this.filter.per_page = res.meta.per_page;
        this.filter.last_page = res.meta.last_page;
        this.filter.length = res.meta.total;
        // this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    async getLiveLinks() {
      // this.loading = true;
      try {
        let res = await this.$store.dispatch(
          "Dashboard/getLiveLinksDataAction"
        );
        this.live_links = res.links;
        // this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
  },
  computed: {
    userData() {
      const data = this.$store.getters["Auth/getUser"];
      return {
        name: data.name,
        email: data.email,
        balance: data.balance,
        role: data.role,
        profileImage: data.image,
        referralLink: data.referral_link,
      };
    },
  },
};
</script>
